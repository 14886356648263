import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import { OrderContext } from "../../provider/order";
import WeekWinnerResult from "../weekResultwinner";
import TopWinnerwinner from "../topWinnerwinner";

import "./index.scss";

const Termresultwinner = ({
  thisWeek,
  lastWeek,
  allTime,
  topLosers,
  topWinners,
}) => {
  const leaderboard = useContext(LeaderboardContext);
  const order = useContext(OrderContext);

  useEffect(() => {
    leaderboard.getWeekResult();
  }, []);

  const lastWeekResult = useCallback(() => {
    let result = [];
    // Commented out because searchInput is not defined
    // if (searchInput.length > 0) {
    //   leaderboard.lastWeekResult.map((el) =>
    //     el.filter((result) => {
    //       result.userName.match(searchInput).then((el) => {
    //         result.push({
    //           userName: el.owner_id.walletAddress,
    //           oldAmount: el.old_amount,
    //           currentAmount: el.current_amount,
    //           lostAmount: el.lost_amount,
    //           is_winner: el.owner_id.is_winner,
    //         });
    //       });
    //     })
    //   );
    // } else {
    leaderboard.lastWeekWinnerResult.map((el) =>
      result.push({
        userName: el.owner_id.walletAddress,
        oldAmount: el.old_amount,
        currentAmount: el.current_amount,
        lostAmount: el.lost_amount,
        is_winner: el.owner_id.is_winner,
        ens: el.owner_id.userENSName,
      })
    );
    // }

    // console.log(result);
    result.sort(function (a, b) {
      return a.oldAmount - a.currentAmount - (b.oldAmount - b.currentAmount);
    });
    //result.reverse();
    console.log(result);

    return result;
  }, [leaderboard.lastWeekWinnerResult]);

  const currentWeekResult = useCallback(() => {
    let result = [];

    leaderboard.currentWeekWinnerResult.map((el) => {
      const currentAmount =
        el.owner_id.usd_balance +
        el.owner_id.usd_holding +
        el.owner_id.eth_balance * order.ethPrice +
        el.owner_id.eth_holding * order.ethPrice;
      // console.log(`${el.owner_id.usd_balance}_${el.owner_id.usd_holding}_${el.owner_id.eth_balance}_${el.owner_id.eth_holding}_${order.ethPrice}`)
      //if search engine active, push only results %username

      result.push({
        userName: el.owner_id.walletAddress,
        oldAmount: el.old_amount,
        currentAmount: currentAmount,
        lostAmount: el.old_amount - currentAmount,
        ens: el.owner_id.userENSName,
      });
    });

    result.sort(function (a, b) {
      return parseFloat(a.lostAmount) - parseFloat(b.lostAmount);
    });
    //result.reverse();
    return result;
  }, [leaderboard.currentWeekWinnerResult, order.ethPrice]);

  return (
    <>
      {thisWeek && (
        <WeekWinnerResult title="Current Week" result={currentWeekResult()} />
      )}
      {lastWeek && (
        <WeekWinnerResult title="Previous Week" result={lastWeekResult()} />
      )}
      {allTime && <TopWinnerwinner result={leaderboard.topWinnerResult} />}
    </>
  );
};

export default Termresultwinner;
