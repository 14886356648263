import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import { OrderContext } from "../../provider/order";
import { UserContext } from "../../provider/user";
import { useMediaQuery } from "react-responsive";

import "./index.scss";

// Assets
import { ReactComponent as CancelIcon } from "../../assets/media/icons/CancelIcon.svg";

const OrderHistory = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const user = useContext(UserContext);
  const order = useContext(OrderContext);
  const [tab, setTab] = useState({
    mode: 0,
    content: "executed, canceled, pending",
  });

  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <div className="order-history-wrapper">
      <div className="order-history">
        <div className="order-history-buttons">
          <div
            className={tab.mode == 0 ? "tab selected" : "tab"}
            onClick={() =>
              setTab({ mode: 0, content: "executed, canceled, pending" })
            }
          >
            All
          </div>
          {!isSmallDevice && (
            <>
              <div
                className={tab.mode == 1 ? "tab selected" : "tab"}
                onClick={() => setTab({ mode: 1, content: "executed" })}
              >
                Executed
              </div>
              <div
                className={tab.mode == 2 ? "tab selected" : "tab"}
                onClick={() => setTab({ mode: 2, content: "pending" })}
              >
                Pending
              </div>
              <div
                className={tab.mode == 3 ? "tab selected" : "tab"}
                onClick={() => setTab({ mode: 3, content: "canceled" })}
              >
                Canceled
              </div>
            </>
          )}
        </div>

        <div className="table_header">
          <span className="table_header_title">No</span>
          <span className="table_header_title">Order Mode</span>
          <span className="table_header_title">Order Type</span>
          <span className="table_header_title">Amount</span>
          <span className="table_header_title">Price</span>
          <span className="table_header_title">Total</span>
          <span className="table_header_title">Created</span>
          <span className="table_header_title">Status</span>
          <span className="table_header_title">Action</span>
        </div>

        <div className="table-content-wrapper">
          {order.myOrders.length !== 0 ? (
            isSmallDevice ? (
              <div className="no-orders-found">
                Please connect with your desktop/laptop device to see your Order
                History.
              </div>
            ) : (
              order.myOrders.map((el, index) => {
                if (tab.content.includes(el.status))
                  return (
                    <div className="table_content" key={index}>
                      <div className="order-type-wrapper">
                        <div className="table_content_info">{index + 1}</div>
                        <div className="table_content_info">
                          {el.order_mode}
                        </div>
                        <div className="table_content_info">
                          {el.order_type}
                        </div>
                      </div>
                      <div className="amount-price-wrapper">
                        <div className="table_content_info">
                          {el.amount.toFixed(2)}
                        </div>
                        <div className="table_content_info">
                          {el.price.toFixed()}
                        </div>
                      </div>
                      <div className="total-date-wrapper">
                        <div className="table_content_info">
                          {(
                            parseFloat(el.price) * parseFloat(el.amount)
                          ).toFixed()}
                        </div>
                        <div className="table_content_info_date">
                          {dayjs(el.created).format("MM-DD, hh:mm")}
                        </div>
                      </div>
                      <div className="statusaction-wrapper">
                        <div className="table_content_info">{el.status}</div>
                        <button
                          className={
                            el.status == "pending"
                              ? "table_content_button"
                              : "table_content_button disabled"
                          }
                          onClick={() => order.cancelOrder(el._id)}
                          disabled={el.status == "pending" ? false : true}
                        >
                          <CancelIcon />
                        </button>
                      </div>
                    </div>
                  );
              })
            )
          ) : user.userInfo.islogin ? (
            <div className="no-orders-found">
              {isSmallDevice
                ? " Please connect with your desktop/laptop device to see your Order History."
                : "No previous record found."}
            </div>
          ) : !isSmallDevice ? (
            <div className="no-orders-found">
              Please connect your wallet to view your order history.
            </div>
          ) : (
            <div className="no-orders-found">
              Please connect with your desktop/laptop device to see your Order
              History.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
