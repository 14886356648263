import React, { useState, useContext } from "react";
import { createButton } from "react-social-login-buttons";
import { useMediaQuery } from "react-responsive";

import { UserContext } from "../../provider/user";
import Modal from "../modal";

import "./index.scss";

// Assets
import truncateWallet from "../../utils/truncateWallet";

const config = {
  text: "Login with Wallet",
  icon: "Ethereum",
  iconFormat: (name) => `fa fa-Ethereum`,
  style: { background: "#000" },
  activeStyle: { background: "#293e69" },
};
const MyEthereumLoginButton = createButton(config);

const LoginButton = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const user = useContext(UserContext);
  const { setSignUpModalShow } = useContext(UserContext);
  const [logInModalShow, setLogInModalShow] = useState(false);

  return (
    <>
      {!user.userInfo.islogin ? (
        <div
          className="login-button"
          onClick={async () => {
            localStorage.removeItem("id");
            localStorage.removeItem("jwt_token");
            let wallet = await user.connectWallet();
            if (wallet) {
              setSignUpModalShow(true);
            }
          }}
        >
          {isSmallDevice ? "Connect" : "Login with Wallet"}
        </div>
      ) : (
        <div onClick={() => user.logout()} className="login-button">
          <span className="user">
            {user.userInfo.userENSName > 30
              ? truncateWallet(user.userInfo.userENSName)
              : user.userInfo.userENSName}
          </span>
          <span className="logout">LOG OUT</span>
        </div>
      )}
    </>
  );
};

export default LoginButton;
