import { Routes, Route } from "react-router-dom";

import "./App.scss";

// Pages
import UserProvider from "./provider/user";
import OrderProvider from "./provider/order";
import LeaderboardProvider from "./provider/leaderboard";
import Home from "./pages/home";
import LeaderBoard from "./pages/leaderboard";
import Profile from "./pages/profile";
import DiscordLoading from "./pages/discordLoading";
import TwitterLoading from "./pages/twitterLoading";
import NavBar from "./components/NavBar/NavBar";
import Error from "./pages/404Error/Error";

function App() {
  return (
    <UserProvider>
      <OrderProvider>
        <LeaderboardProvider>
          <NavBar />
          <Routes>
            <Route path="/*" element={<Error />} />
            <Route path="/" element={<Home />} />
            <Route path="/discord-auth" element={<DiscordLoading />} />
            <Route path="/twitter-auth" element={<TwitterLoading />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </LeaderboardProvider>
      </OrderProvider>
    </UserProvider>
  );
}

export default App;
