import React, { useRef } from "react";
import useOnClickOutside from "./useClickOutside";

import "./index.scss";

// Assets

import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
const Modal = (props) => {
  const ref = useRef();
  useOnClickOutside(ref, props.close);

  return (
    <>
      {props.visible && (
        <div className="modal-back">
          <div
            className={
              props.extend == true ? "modal-custom-extend" : "modal-custom"
            }
            ref={ref}
          >
            <div className="modal-header">
              <div className="modal-title">{props.title}</div>
              <div className="modal-close" onClick={props.close}>
                <CloseIcon />
              </div>
            </div>
            <div className="term-div">{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
