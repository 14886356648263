import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants/env";

const LeaderboardContextTemplate = {
  lastWeekResult: [{}],
  currentWeekResult: [{}],
  topResult: [{}],
  lastWeekWinnerResult: [{}],
  currentWeekWinnerResult: [{}],
  topWinnerResult: [{}],
  getWeekResult: () => {},
  getRoundInfo: () => {},
};

const LeaderboardContext = React.createContext(LeaderboardContextTemplate);

const LeaderboardProvider = ({ children }) => {
  const [lastWeekResult, setLastWeekResult] = useState([]);
  const [currentWeekResult, setCurrentWeekResult] = useState([]);
  const [topResult, setTopResult] = useState([]);

  const [lastWeekWinnerResult, setLastWeekWinnerResult] = useState([]);
  const [currentWeekWinnerResult, setCurrentWeekWinnerResult] = useState([]);
  const [topWinnerResult, setTopWinnerResult] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const [winnerLeaderboard, setWinnerLeaderboard] = useState([]);
  const [loserLeaderboard, setLoserLeaderboard] = useState([]);

  const getWeekResult = async () => {
    axios.get(`${SERVER_URL}api/roundresult/current`).then((result) => {
      if (result.data.status) {
        setCurrentWeekResult(result.data.data);
        console.log(currentWeekResult);
      }
    });

    axios.get(`${SERVER_URL}api/roundresult/last`).then((result) => {
      if (result.data.status) {
        setLastWeekResult(result.data.data);
      }
    });

    axios.get(`${SERVER_URL}api/roundresult/top`).then((result) => {
      if (result.data.status) {
        setTopResult(result.data.data);
      }
    });

    axios.get(`${SERVER_URL}api/roundresult/winnerCurrent`).then((result) => {
      if (result.data.status) {
        setCurrentWeekWinnerResult(result.data.data);
        console.log(currentWeekWinnerResult);
      }
    });

    axios.get(`${SERVER_URL}api/roundresult/winnerLast`).then((result) => {
      if (result.data.status) {
        setLastWeekWinnerResult(result.data.data);
      }
    });

    axios.get(`${SERVER_URL}api/roundresult/winnerTop`).then((result) => {
      if (result.data.status) {
        setTopWinnerResult(result.data.data);
      }
    });
  };

  const filteredTopResult = useMemo(() => {
    if (searchInput.length > 0) {
      return topResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return topResult;
    }
  }, [searchInput, topResult]);

  const filteredLastWeekResult = useMemo(() => {
    if (searchInput.length > 0) {
      return lastWeekResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return lastWeekResult;
    }
  }, [searchInput, lastWeekResult]);

  const filteredCurrentWeekResult = useMemo(() => {
    if (searchInput.length > 0) {
      return currentWeekResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return currentWeekResult;
    }
  }, [searchInput, currentWeekResult]);

  const filteredTopWinnerResult = useMemo(() => {
    if (searchInput.length > 0) {
      return topWinnerResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return topResult;
    }
  }, [searchInput, topWinnerResult]);

  const filteredLastWeekWinnerResult = useMemo(() => {
    if (searchInput.length > 0) {
      return lastWeekWinnerResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return lastWeekWinnerResult;
    }
  }, [searchInput, lastWeekResult]);

  const filteredCurrentWeekWinnerResult = useMemo(() => {
    if (searchInput.length > 0) {
      return currentWeekWinnerResult.filter((result) => {
        return result?.owner_id?.walletAddress.match(searchInput);
      });
    } else {
      return currentWeekWinnerResult;
    }
  }, [searchInput, currentWeekWinnerResult]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const handleSearch = (e) => {
    setSearchInput(escapeRegExp(e.target.value.toLowerCase()));
  };

  return (
    <LeaderboardContext.Provider
      value={{
        topResult,
        lastWeekResult,
        currentWeekResult,

        currentWeekWinnerResult,
        lastWeekWinnerResult,
        topWinnerResult,

        getWeekResult,
        searchInput,
        setSearchInput,
        handleSearch,
        filteredTopResult,
        filteredLastWeekResult,
        filteredCurrentWeekResult,

        filteredTopWinnerResult,
        filteredLastWeekWinnerResult,
        filteredCurrentWeekWinnerResult,

        winnerLeaderboard,
        setWinnerLeaderboard,
        loserLeaderboard,
        setLoserLeaderboard,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

export { LeaderboardContext };
export default LeaderboardProvider;
