import React, { useState, useContext, useEffect } from "react";
import { AdvancedChart } from "react-tradingview-embed";
import "./index.scss";

import Header from "../../components/header";
import OrderHistory from "../../components/orderHistory";
import OrderMarket from "../../components/orderMarket";
import RoundInfo from "../../components/roundInfo";
import Footer from "../../components/Footer/Footer";

// Assets
const Home = (props) => {
  useEffect(() => {
    document.title = "BarBearians • ETH Simulator";
  }, []);

  return (
    <>
      <div className="home">
        <Header />
        <div className="home-top-section">
          <div className="home-top-inner">
            <div className="tradingview_chart">
              <AdvancedChart
                widgetProps={{
                  interval: "1D",
                  colorTheme: "dark",
                  symbol: "ETHUSD",
                }}
              />
            </div>

            <div className="home-top-inner-right">
              <RoundInfo />
              <OrderMarket />
            </div>
          </div>
        </div>

        <div className="home-bottom-section">
          <OrderHistory />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
