import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { LeaderboardContext } from "../../provider/leaderboard";

import "./NavBar.scss";

// Assets
import BlackLogo from "../../assets/media/icons/BlackLogo.png";
import { ReactComponent as HamburgerIcon } from "../../assets/media/icons/HamburgerMenu.svg";
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
import LoginButton from "../loginButton";

const NavBar = () => {
  const location = useLocation();
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] = useState(false);

  const { winnerLeaderboard } = useContext(LeaderboardContext);

  const isLeaderboardPage = location.pathname === "/leaderboard";
  return (
    <>
      <div
        className={
          isMobileSideMenuOpen ? "mobile-side-menu active" : "mobile-side-menu"
        }
      >
        <div className="close-btn-wrapper">
          <div
            onClick={() => setIsMobileSideMenuOpen(false)}
            className="close-btn"
          >
            <CloseIcon />
          </div>
        </div>
        <a
          href="https://barbearians-front-end.pages.dev/"
          rel="noreferrer"
          target={"_blank"}
        >
          HOME
        </a>
        <a
          href="https://barbearians-front-end.pages.dev/missions"
          rel="noreferrer"
          target={"_blank"}
        >
          MISSIONS
        </a>
        <a
          href="https://barbearians-front-end.pages.dev/whitelist-quests"
          rel="noreferrer"
          target={"_blank"}
        >
          WHITELIST QUESTS
        </a>
        {/* <a
          href="https://barbearians-front-end.pages.dev/theallowlist"
          rel="noreferrer"
          target={"_blank"}
        >
          THE ALLOWLIST
        </a> */}
        {/* <a
          href="https://barbearians-front-end.pages.dev/WHITEpaper"
          rel="noreferrer"
          target={"_blank"}
        >
          WHITEPAPER
        </a> */}
      </div>

      <div className="navbar">
        <div className="navbar-top">
          <div className="nav-left-side">
            <img
              onClick={() =>
                window.open(
                  "https://barbearians-front-end.pages.dev/",
                  "_blank"
                )
              }
              src={BlackLogo}
              alt=""
            />
            <a
              href="https://barbearians-front-end.pages.dev/"
              rel="noreferrer"
              target={"_blank"}
            >
              HOME
            </a>
            <a
              href="https://barbearians-front-end.pages.dev/missions"
              rel="noreferrer"
              target={"_blank"}
            >
              MISSIONS
            </a>
            <a
              href="https://barbearians-front-end.pages.dev/whitelist-quests"
              rel="noreferrer"
              target={"_blank"}
            >
              WHITELIST QUESTS
            </a>
            {/* <a
              href="https://barbearians-front-end.pages.dev/theallowlist"
              rel="noreferrer"
              target={"_blank"}
            >
              THE ALLOWLIST
            </a> */}
            {/* <a
              href="https://barbearians-front-end.pages.dev/whitepaper"
              rel="noreferrer"
              target={"_blank"}
            >
              WHITEPAPER
            </a> */}
          </div>
          <div className="nav-right-side">
            <LoginButton />
            {/* Mobile side */}
            <div
              onClick={() => setIsMobileSideMenuOpen(true)}
              className="mobile-side"
            >
              <HamburgerIcon className="mobile-side-icon" />
            </div>
            {/* Mobile side */}
          </div>
        </div>
        <div className="navbar-bottom">
          <div className="allowlist-game">ALLOWLIST GAME</div>
          <div className="eth-simulator">
            {isLeaderboardPage
              ? winnerLeaderboard
                ? "WINNER LEADERBOARD"
                : "LOSER LEADERBOARD"
              : "ETH SIMULATOR"}
          </div>
          <div className="navbar-bottom-gradient" />
        </div>
      </div>
    </>
  );
};

export default NavBar;
