import React, { useContext, useEffect, useState } from "react";
import truncateWallet from "../../utils/truncateWallet";
import { LeaderboardContext } from "../../provider/leaderboard";
import { UserContext } from "../../provider/user";
import { toast } from "react-toastify";

import "./index.scss";

// Assets
import { ReactComponent as AllowedIcon } from "../../assets/media/icons/Allowed.svg";

const WeekResult = (props) => {
  const user = useContext(UserContext);
  const { searchInput } = useContext(LeaderboardContext);
  const [winners, setWinners] = useState([]);
  const [users, setUsers] = useState([]);
  const winnerClassName = "weekly-canditate-wrapper winner";
  const nonWinnerClassName = "weekly-canditate-wrapper non-winner";

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  useEffect(() => {
    if (searchInput) {
      const filteredUsers = props.result.filter((user) =>
        user.ens.toLowerCase().includes(searchInput)
      );
      setUsers(filteredUsers);
    } else {
      setUsers(props.result);
    }
  }, [props.result, searchInput]);

  useEffect(() => {
    if (users.length > 0) {
      // Sort the props.users based on their score in descending order
      const sortedUsers = users.sort((a, b) => b.score - a.score);
      // Take the top 100 users
      const top100 = sortedUsers.slice(0, 100);
      setWinners(top100);
    }
  }, [users, props.result]);

  // rateLostRaise function that calculates the rate of lost or raise
  const rateLostRaise = (prev, current) => {
    const rate = (-(prev - current) / prev) * 100;
    // console.log(rate);
    if (rate < 0) return `${rate.toFixed(2)}%`;
    if (rate > 0) return `${rate.toFixed(2)}%`;
    if (rate == 0) return "0.00%";
  };

  // lostOrRaise function that checks if the rate is positive or negative
  const lostOrRaise = (prev, current) => {
    const rate = ((prev - current) / prev) * 100;
    if (rate < 0) return "green";
    if (rate > 0) return "red";
    if (rate == 0) return "white";
  };

  const copyHandler = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    notifySuccess("Wallet address copied to clipboard.");
  };

  return users.length > 0 ? (
    users.map((el, index) => (
      <div
        onClick={() => copyHandler(el.userName)}
        className={winners.includes(el) ? winnerClassName : nonWinnerClassName}
        key={index}
      >
        <div className="weekly-candidate-left-side">
          <div className="weekly-rank" key={index}>
            {winners.includes(el) && <AllowedIcon />}
            <div className="weekly-rank-number">{index + 1}</div>
          </div>
          <div className="weekly-canditate-username">
            {el.ens.length > 30 ? truncateWallet(el.ens) : el.ens}
          </div>
        </div>
        <div className="weekly-candidate-right-side">
          <div className="startend-wrapper">
            <div className="weekly-canditate-startamount">
              {parseFloat(el.oldAmount).toFixed()}
            </div>
            <div className="weekly-canditate-endamount">
              {parseFloat(el.currentAmount).toFixed()}
            </div>
          </div>
          <div className="weekly-candidate-lostgainedamount">
            <span>
              {parseFloat(
                -(el.oldAmount - parseFloat(el.currentAmount))
              ).toFixed()}
              $
            </span>
            <span
              className={`rate-lost-raise ${lostOrRaise(
                el.oldAmount,
                el.currentAmount
              )}`}
            >
              (
              {rateLostRaise(
                parseFloat(el.oldAmount),
                parseFloat(el.currentAmount)
              )}
              )
            </span>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="no-data">No participants were found.</div>
  );
};

export default WeekResult;

{
  /* <div className="weekly-canditate-wrapper" key={index}>
        <div className="weekly-candidate-left-side">
          <div className={rankCheck(index)}>
            {index === 0 && <img src={GoldMedal} alt="" />}
            {index === 1 && <img src={SilverMedal} alt="" />}
            {index === 2 && <img src={BronzeMedal} alt="" />}
            <div className="weekly-rank-number">{index + 1}</div>
          </div>
          <div className="weekly-canditate-username">
            {truncateWallet(el.userName)}
          </div>
        </div>

        <div className="weekly-candidate-right-side">
          <div className="startend-wrapper">
            <div className="weekly-canditate-startamount">
              {parseFloat(el.oldAmount).toFixed()}
            </div>
            <div className="weekly-canditate-endamount">
              {parseFloat(el.currentAmount).toFixed()}
            </div>
          </div>
          <div className="weekly-candidate-lostgainedamount">
            <span>
              {parseFloat(
                -(el.oldAmount - parseFloat(el.currentAmount))
              ).toFixed(2)}
            </span>
            <span
              className={`rate-lost-raise ${lostOrRaise(
                el.oldAmount,
                el.currentAmount
              )}`}
            >
              (
              {rateLostRaise(
                parseFloat(el.oldAmount),
                parseFloat(el.currentAmount)
              )}
              )
            </span>
          </div>
        </div>
      </div> */
}
