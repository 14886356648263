import React, { useState, useContext, useEffect, useRef } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import { UserContext } from "../../provider/user";
import { useMediaQuery } from "react-responsive";

import "./index.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/header";
import Termresult from "../../components/termresult";
import Termresultwinner from "../../components/termresultwinner";

// Assets
import { ReactComponent as SearchIcon } from "../../assets/media/icons/SearchIcon.svg";
import LoginButton from "../../components/loginButton";

const LeaderBoard = (props) => {
  const isTabletDevice = useMediaQuery({ query: "(max-width: 889px)" });
  const isSmallDevice = useMediaQuery({ query: "(max-width: 480px)" });
  const searchBarRef = useRef(null);
  const searchBarRefMobile = useRef(null);

  const [thisWeek, setThisWeek] = useState(true);
  const [lastWeek, setLastWeek] = useState(false);
  const [allTime, setAllTime] = useState(false);

  const user = useContext(UserContext);
  const { handleSearch, setSearchInput, winnerLeaderboard, loserLeaderboard } =
    useContext(LeaderboardContext);
  const { setShowFAQmodalLeaderboard } = useContext(UserContext);

  useEffect(() => {
    document.title = "BarBearians • ETH Simulator";
  }, []);

  useEffect(() => {
    const handleReset = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        // Reset the search bar here
        searchBarRef.current.value = "";
        searchBarRefMobile.current.value = "";
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleReset);

    return () => {
      document.removeEventListener("mousedown", handleReset);
    };
  }, []);

  const handleBackspace = (event) => {
    if (event.keyCode === 8) {
      // If backspace key is pressed, clear search input
      searchBarRef.current.value = "";
      searchBarRefMobile.current.value = "";
      setSearchInput("");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleBackspace);

    return () => {
      window.removeEventListener("keydown", handleBackspace);
    };
  }, []);

  // Chart navigation buttons
  const chartButtonHandler = (pageName) => {
    switch (pageName) {
      case "thisWeek":
        setThisWeek(true);
        setLastWeek(false);
        setAllTime(false);
        break;

      case "lastWeek":
        setThisWeek(false);
        setLastWeek(true);
        setAllTime(false);
        break;

      case "allTime":
        setThisWeek(false);
        setLastWeek(false);
        setAllTime(true);
        break;

      case "topWinners":
        setThisWeek(false);
        setLastWeek(false);
        setAllTime(false);
        break;

      case "topLosers":
        setThisWeek(false);
        setLastWeek(false);
        setAllTime(false);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="leaderboard">
        <Header />
        <div className="search-mobile">
          <SearchIcon className="search-icon" />
          <input
            ref={searchBarRefMobile}
            type="text"
            className="leaderboard-search"
            placeholder="SEARCH ADDRESS"
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="leaderboard-page-top">
          <div
            className={user.userInfo.islogin ? "left-side logged" : "left-side"}
          >
            {winnerLeaderboard && (
              <div className="leaderboard-chart-wrapper">
                <div className="leaderboard-chart-top-buttons">
                  <div className="leaderboard-chart-top-left">
                    <div
                      className={
                        thisWeek
                          ? "this-week-button active"
                          : "this-week-button"
                      }
                      onClick={() => chartButtonHandler("thisWeek")}
                    >
                      THIS WEEK
                    </div>
                    <div
                      className={
                        lastWeek
                          ? "last-week-button active"
                          : "last-week-button"
                      }
                      onClick={() => chartButtonHandler("lastWeek")}
                    >
                      LAST WEEK
                    </div>
                    <div
                      className={
                        allTime ? "all-time-button active" : "all-time-button"
                      }
                      onClick={() => chartButtonHandler("allTime")}
                    >
                      ALL TIME
                    </div>
                  </div>
                  <div className="leaderboard-chart-top-right">
                    <SearchIcon className="search-icon" />
                    <input
                      ref={searchBarRef}
                      type="text"
                      className="leaderboard-search"
                      placeholder="SEARCH ADDRESS"
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="leaderboard-top-table-headers">
                  <div className="leaderboard-top-table-header-left">
                    <div className="leaderboard-top-table-header-rank">
                      RANK
                    </div>
                    <div className="leaderboard-top-table-header-wallet-address">
                      {!isSmallDevice && "WALLET"} ADDRESS
                    </div>
                  </div>
                  <div className="leaderboard-top-table-header-right">
                    {!allTime && !isSmallDevice && (
                      <>
                        <div className="leaderboard-top-table-header-start-amount">
                          START {!isTabletDevice && "AMOUNT"}
                        </div>
                        <div className="leaderboard-top-table-header-end-amount">
                          END {!isTabletDevice && "AMOUNT"}
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        position: allTime && "absolute",
                        right: allTime ? "0" : "auto",
                      }}
                      className="leaderboard-top-table-header-lost-gained-amount"
                    >
                      L/G {!isSmallDevice && "AMOUNT"}
                    </div>
                  </div>
                </div>
                <div className="leaderboard-list">
                  <Termresultwinner
                    thisWeek={thisWeek}
                    lastWeek={lastWeek}
                    allTime={allTime}
                  />
                </div>
              </div>
            )}

            {loserLeaderboard && (
              <div className="leaderboard-chart-wrapper">
                <div className="leaderboard-chart-top-buttons">
                  <div className="leaderboard-chart-top-left">
                    <div
                      className={
                        thisWeek
                          ? "this-week-button active"
                          : "this-week-button"
                      }
                      onClick={() => chartButtonHandler("thisWeek")}
                    >
                      THIS WEEK
                    </div>
                    <div
                      className={
                        lastWeek
                          ? "last-week-button active"
                          : "last-week-button"
                      }
                      onClick={() => chartButtonHandler("lastWeek")}
                    >
                      LAST WEEK
                    </div>
                    <div
                      className={
                        allTime ? "all-time-button active" : "all-time-button"
                      }
                      onClick={() => chartButtonHandler("allTime")}
                    >
                      ALL TIME
                    </div>
                  </div>
                  <div className="leaderboard-chart-top-right">
                    <SearchIcon className="search-icon" />
                    <input
                      ref={searchBarRef}
                      type="text"
                      className="leaderboard-search"
                      placeholder="SEARCH ADDRESS"
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="leaderboard-top-table-headers">
                  <div className="leaderboard-top-table-header-left">
                    <div className="leaderboard-top-table-header-rank">
                      RANK
                    </div>
                    <div className="leaderboard-top-table-header-wallet-address">
                      {!isSmallDevice && "WALLET"} ADDRESS
                    </div>
                  </div>
                  <div className="leaderboard-top-table-header-right">
                    {!allTime && !isSmallDevice && (
                      <>
                        <div className="leaderboard-top-table-header-start-amount">
                          START {!isTabletDevice && "AMOUNT"}
                        </div>
                        <div className="leaderboard-top-table-header-end-amount">
                          END {!isTabletDevice && "AMOUNT"}
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        position: allTime && "absolute",
                        right: allTime ? "0" : "auto",
                      }}
                      className="leaderboard-top-table-header-lost-gained-amount"
                    >
                      L/G {!isSmallDevice && "AMOUNT"}
                    </div>
                  </div>
                </div>
                <div className="leaderboard-list">
                  <Termresult
                    thisWeek={thisWeek}
                    lastWeek={lastWeek}
                    allTime={allTime}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className={
              user.userInfo.islogin ? "right-side logged" : "right-side"
            }
          >
            {!user.userInfo.islogin && (
              <>
                <div
                  style={{ height: user.userInfo.islogin ? "4.5rem" : "8rem" }}
                  className={
                    user.userInfo.islogin
                      ? "region-filters-box-logged"
                      : "region-filters-box"
                  }
                >
                  <div className="rules-eth-sim">
                    <span>RULES</span> TO USE ETH SIMULATOR
                  </div>
                  <div className="logintojoin-leaderboard">
                    LOGIN TO JOIN LEADERBOARD
                    <div className="login-button-leaderboard">
                      <LoginButton />
                    </div>
                  </div>
                </div>
              </>
            )}

            {!user.userInfo.islogin && (
              <div className="leaderboard-warning">
                You are currently not the TOP 100 biggest bear of the week!
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeaderBoard;
