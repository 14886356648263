import React from "react";
import Marquee from "react-fast-marquee";

import "./Footer.scss";

// Assets
import { ReactComponent as FooterTop } from "../../assets/media/icons/FooterContainer.svg";
import FooterCoin from "../../assets/media/icons/FooterCoin.gif";
import TwitterPixelLogo from "../../assets/media/icons/TwitterPixel.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top-wrapper">
        <div className="gold-effect" />
        <img src={FooterCoin} alt="" />
        <FooterTop className="footer-top" />
      </div>
      <Marquee className="footer-marquee" gradient={false}>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
        <span>BEAR WITH US</span>
      </Marquee>
      <div className="footer-bottom">
        <div className="footer-bottom-left">
          <div className="footer-barberians">© BARBEARIANS 2022</div>
          <div className="footer-terms">TERMS</div>
          <div className="footer-faq">FAQ</div>
        </div>
        <div className="footer-bottom-right">
          <a
            href="https://twitter.com/barbeariansnft"
            target={"_blank"}
            rel="noreferrer"
          >
            <div className="twitter-button">
              <img width={50} src={TwitterPixelLogo} alt="" />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
