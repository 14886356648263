import React, { useState, useContext, useEffect } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UserContext } from "../../provider/user";
import { OrderContext } from "../../provider/order";
import LoginButton from "../loginButton";

const Market = () => {
  const user = useContext(UserContext);
  const order = useContext(OrderContext);
  const { loader } = useContext(OrderContext);
  const [usd, setUSD] = useState(0);
  const [eth, setETH] = useState(0);
  const [showBy, setShowBy] = useState("eth");
  const [basePrice, setbasePrice] = useState(0);

  // Create a function to handle toast function
  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  const showChangePriceByChange = (SHOWBY, BASEPRICE) => {
    if (SHOWBY) {
      if (SHOWBY === "usd") {
        setUSD(BASEPRICE);
        setETH(BASEPRICE / order.ethPrice);
      } else {
        setETH(BASEPRICE);
        setUSD(BASEPRICE * order.ethPrice);
      }
    }
  };

  useEffect(() => {
    if (showBy === "usd") {
      setETH(usd / order.ethPrice);
    } else {
      setUSD(eth * order.ethPrice);
    }
    console.log(
      "order.ethPrice:=" + order.ethPrice + " usd:=" + usd + " eth:=" + eth
    );
  }, [order.ethPrice]);

  const sellFunction = () => {
    if (user.userInfo.eth < eth) {
      notifyError("You dont have enough ETH!");
      return;
    }
    order.makeMarket({
      orderType: "sell",
      amount: eth,
      price: order.ethPrice,
    });
  };

  const buyFunction = () => {
    if (user.userInfo.usd < usd) {
      notifyError("You dont have enough USD!");
      return;
    }
    order.makeMarket({
      orderType: "buy",
      amount: eth,
      price: order.ethPrice,
    });
  };

  return (
    <>
      <div className="buy-sell">
        <div className="input-wrapper">
          <span>Amount(ETH): </span>
          <input
            type="number"
            min={0}
            value={eth}
            onChange={(e) => {
              setShowBy("eth");
              setbasePrice(e.target.value);
              showChangePriceByChange("eth", e.target.value);
            }}
          />
        </div>

        <div className="input-wrapper">
          <span>Amount(USD): </span>
          <input
            type="number"
            min={0}
            value={usd}
            onChange={(e) => {
              setShowBy("usd");
              setbasePrice(e.target.value);
              showChangePriceByChange("usd", e.target.value);
            }}
          />
        </div>

        {user.userInfo.islogin ? (
          !loader ? (
            <div className="buysell-buttons-wrapper">
              <div className="buysell-button" onClick={buyFunction}>
                Buy
              </div>
              <div className="buysell-button" onClick={sellFunction}>
                Sell
              </div>
            </div>
          ) : (
            <div className="buysell-buttons-wrapper text-center">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )
        ) : (
          <LoginButton />
        )}

        <div className="buysell-warning">
          WARNING: This is fake ETH. There won't be any MetaMask TXN.
        </div>
      </div>
    </>
  );
};

export default Market;
