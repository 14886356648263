import React, { useState, useContext } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // ReactToastify package's CSS

import { UserContext } from "../../provider/user";
import { OrderContext } from "../../provider/order";

import LoginButton from "../loginButton";

const BuySell = () => {
  const user = useContext(UserContext);
  const order = useContext(OrderContext);
  const { loader } = useContext(OrderContext);
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  const sellFunction = () => {
    if (user.userInfo.eth < amount) {
      user.walletConnect;
      return;
    }
    order.makeOrder({
      orderType: "sell",
      amount: amount,
      price: price,
    });
    setAmount(0);
    setPrice(0);
  };

  const buyFunction = () => {
    if (user.userInfo.usd < amount * price) {
      notifyError("You dont have enough USD!");
      return;
    }
    order.makeOrder({
      orderType: "buy",
      amount: amount,
      price: price,
    });

    setAmount(0);
    setPrice(0);
  };

  return (
    <>
      <div className="buy-sell">
        <div className="input-wrapper">
          <label htmlFor="eth-amount">Amount(ETH): </label>
          <input
            id="eth-amount"
            type="number"
            min={0}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="usd-amount">Price($): </label>
          <input
            id="usd-amount"
            type="number"
            min={0}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <span>TOTAL($): </span>
          <div className="total-eth-value">{price * amount}</div>
        </div>

        {user.userInfo.islogin ? (
          !loader ? (
            <div className="buysell-buttons-wrapper">
              <div className="buysell-button" onClick={buyFunction}>
                Buy
              </div>
              <div className="buysell-button" onClick={sellFunction}>
                Sell
              </div>
            </div>
          ) : (
            <div className="buysell-buttons-wrapper text-center">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )
        ) : (
          <LoginButton />
        )}

        <div className="buysell-warning">
          WARNING: This is fake ETH. There won't be any MetaMask TXN.
        </div>
      </div>
    </>
  );
};

export default BuySell;
