import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { UserContext } from "../../provider/user";
import { SERVER_URL } from "../../constants/env";
import { toast } from "react-toastify";

import "./index.scss";

const DiscordLoading = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirect, setRedirect] = useState(false);
  const [mounted, setMounted] = useState(true);
  const user = useContext(UserContext);

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  useEffect(() => {
    if (mounted) loginWithDiscord();

    setMounted(false);
    return () => setMounted(false);
  }, []);

  const loginWithDiscord = async () => {
    const result = await user
      .loginWithDiscord(searchParams.get("code"), searchParams.get("state"))
      .then((res) => {
        if (res.status) {
          setRedirect(true);
        } else {
          notifyError(`${res.message}.`);
          setTimeout(function () {
            setRedirect(true);
          }, 3000);
        }
      });
  };
  return (
    <div className="App" style={{ background: "rgba(0, 0, 0, 0.85)" }}>
      {redirect ? (
        <Navigate to="/" />
      ) : (
        <div className="full-content content-center">Authorizing...</div>
      )}
    </div>
  );
};

export default DiscordLoading;
