import { useState, useContext, useEffect, useCallback } from "react";
import { OrderContext } from "../../provider/order";
import { UserContext } from "../../provider/user";

import "./index.scss";

// Assets
import USDPixelized from "../../assets/media/icons/USDPixelized.png";
import ETHPixelized from "../../assets/media/icons/EthereumPixelized.png";

let timer;

const RoundInfo = () => {
  const [lastSeconds, setLastSeconds] = useState(0);
  const [showFullETHCount, setShowFullETHCount] = useState(false);
  const order = useContext(OrderContext);
  const user = useContext(UserContext);

  useEffect(() => {
    order.getRoundInfo();
  }, []);

  useEffect(() => {
    if (!order.roundInfo) return;

    const intervalId = setInterval(() => {
      const timeline = new Date(order.roundInfo.created);
      timeline.setSeconds(timeline.getSeconds() + order.roundInfo.duration);

      const diffMs = timeline - new Date();
      if (diffMs <= 0) {
        clearInterval(intervalId);
        return;
      }

      const day = Math.floor(diffMs / (1000 * 60 * 60 * 24));
      const hour = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
      const min = Math.floor((diffMs / (1000 * 60)) % 60);
      const sec = Math.floor((diffMs / 1000) % 60);

      const dayString = day > 0 ? `${day} D${day > 1 ? "s" : ""} : ` : "";
      const timeString = `${dayString}${hour}H : ${min}M : ${sec}S`;

      setLastSeconds(timeString);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [order.roundInfo]);

  return (
    <div className="home-info-box">
      <div className="home-info-box-left">
        <div className="round-time-wrapper">
          <span style={{ color: "#dedede75" }}>WEEKLY COUNTDOWN:</span>
          {order.roundInfo ? (
            <span style={{ color: "#dedede" }}>
              {" "}
              {lastSeconds || "Calculating..."}
            </span>
          ) : (
            <span style={{ color: "#dedede75" }}>Loading...</span>
          )}
        </div>
        {user.userInfo.islogin && (
          <div className="portfolio">
            <div className="eth">
              <div
                className={
                  showFullETHCount ? "eth-count-full show" : "eth-count-full"
                }
              >
                <div className="full-eth">{user.userInfo.eth}</div>
                <img src={ETHPixelized} alt="" />
              </div>
              <div
                onMouseOver={() => setShowFullETHCount(true)}
                onMouseLeave={() => setShowFullETHCount(false)}
                className="eth-count"
              >
                {(user.userInfo.eth + "").slice(0, 5)} ETH
                <img src={ETHPixelized} alt="" />
              </div>
            </div>
            <div className="usd-count">
              {Math.floor(user.userInfo.usd)} USD
              <img src={USDPixelized} alt="" />
            </div>
          </div>
        )}
      </div>
      {user.userInfo.islogin && (
        <div className="portfolio">
          <div className="balance">BALANCE:</div>
          <div className="eth-count">
            {user.userInfo.eth} ETH <img src={ETHPixelized} alt="" />
          </div>
          <div className="usd-count">
            {user.userInfo.usd} USD <img src={USDPixelized} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default RoundInfo;
