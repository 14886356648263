import React, { useState, useContext } from "react";
import BuySell from "../../components/buysell";
import Market from "../../components/market";
import { OrderContext } from "../../provider/order";
const OrderMarket = (props) => {
  const [mode, setMode] = useState(0);
  const order = useContext(OrderContext);
  return (
    <div className="buysell-container">
      <div className="buysellbox-buttons-wrapper">
        <div
          className={
            mode == 0 ? "limitorder-button clicked" : "limitorder-button"
          }
          onClick={() => setMode(0)}
        >
          Limit Order
        </div>
        <div
          className={
            mode == 1 ? "ordermarket-button clicked" : "ordermarket-button"
          }
          onClick={() => setMode(1)}
        >
          Market
        </div>
      </div>
      {mode == 1 && <>{order.ethPrice > 1 ? <Market /> : "loading"}</>}
      {mode == 0 && <BuySell />}
    </div>
  );
};

export default OrderMarket;
