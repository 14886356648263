import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { LeaderboardContext } from "../../provider/leaderboard";
import { UserContext } from "../../provider/user";
import "./index.scss";

// Assets
import GoldMedal from "../../assets/media/icons/goldMedal.png";
import SilverMedal from "../../assets/media/icons/silverMedal.png";
import BronzeMedal from "../../assets/media/icons/bronzeMedal.png";
import truncateWallet from "../../utils/truncateWallet";

export const TopWinnerwinItem = ({ leaderboard, index }) => {
  const isTabletDevice = useMediaQuery({ query: "(max-width: 889px)" });
  const isSmallDevice = useMediaQuery({ query: "(max-width: 480px)" });
  const user = useContext(UserContext);
  // rateLostRaise function that calculates the rate of lost or raise
  const rateLostRaise = (prev, current) => {
    const rate = (-(prev - current) / prev) * 100;
    // console.log(rate);
    if (rate < 0) return `${rate.toFixed(2)}%`;
    if (rate > 0) return `+${rate.toFixed(2)}%`;
    if (rate == 0) return "0.00%";
  };

  // lostOrRaise function that checks if the rate is positive or negative
  const lostOrRaise = (prev, current) => {
    const rate = ((prev - current) / prev) * 100;
    if (rate < 0) return "green";
    if (rate > 0) return "red";
    if (rate == 0) return "white";
  };

  // rankCheck function that checks the rank of the user and returns the corresponding class
  const rankCheck = (index) => {
    switch (index) {
      case 0:
        return "top-rank one";
      case 1:
        return "top-rank two";
      case 2:
        return "top-rank three";
      default:
        return "top-rank";
    }
  };

  // Make the another version for top canditate warapper
  const topCanditateWrapper = (index) => {
    switch (index) {
      case 0:
        return "top-canditate-wrapper gold";
      case 1:
        return "top-canditate-wrapper silver";
      case 2:
        return "top-canditate-wrapper bronze";
      default:
        return "top-canditate-wrapper";
    }
  };

  return (
    <div className={topCanditateWrapper(index)}>
      <div className="top-candidate-left-side">
        <div className={rankCheck(index)}>
          {index === 0 && <img src={GoldMedal} alt="" />}
          {index === 1 && <img src={SilverMedal} alt="" />}
          {index === 2 && <img src={BronzeMedal} alt="" />}
          <div className="top-rank-number">{index + 1}</div>
        </div>
        <div className="top-wallet-adress">
          {!isTabletDevice
            ? leaderboard.user_wallet
            : leaderboard.user_wallet.length > 30
            ? truncateWallet(leaderboard.user_wallet)
            : leaderboard.user_wallet}
        </div>
      </div>

      <div className="top-canditate-right-side">
        {parseFloat(-leaderboard.lost_amount).toFixed()}$
        {!isSmallDevice && " | "}
        <span
          className={lostOrRaise(
            leaderboard.old_amount,
            leaderboard.current_amount
          )}
        >
          {rateLostRaise(leaderboard.old_amount, leaderboard.current_amount)}
        </span>
      </div>
    </div>
  );
};
