import { useState, useContext, useEffect } from "react";
import { nanoid } from "nanoid";

import { TopWinnerItem } from "./topWinnerItem.jsx";
import { LeaderboardContext } from "../../provider/leaderboard";

import "./index.scss";

const TopWinner = (props) => {
  const { searchInput, setSearchInput, filteredTopResult } =
    useContext(LeaderboardContext);
  const [topResult, setTopResult] = useState([]);

  useEffect(() => {
    setTopResult(filteredTopResult);
    if (searchInput) {
      const filtered = topResult.filter((res) => {
        return res.user_wallet.toLowerCase().includes(searchInput);
      });
      setTopResult(filtered);
    } else {
      setTopResult(filteredTopResult);
    }
  }, [searchInput, filteredTopResult]);

  return (
    <>
      {topResult.length > 0 ? (
        topResult.map((res, index) => (
          <div key={nanoid()}>
            <TopWinnerItem index={index} leaderboard={res} />
          </div>
        ))
      ) : (
        <div className="no-result">
          <p>No result found...</p>
        </div>
      )}
    </>
  );
};

export default TopWinner;
