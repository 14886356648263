import { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../provider/user";
import { LeaderboardContext } from "../../provider/leaderboard";
import { OrderContext } from "../../provider/order";
import { useMediaQuery } from "react-responsive";
import { createButton } from "react-social-login-buttons";

import gsap from "gsap";

import "./index.scss";
import LoginButton from "../loginButton";
import Modal from "../modal";

// Assets
import { ReactComponent as InfoIcon } from "../../assets/media/icons/InfoIcon.svg";
import USDPixelized from "../../assets/media/icons/USDPixelized.png";
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";

const config = {
  text: "Login with Wallet",
  icon: "Ethereum",
  iconFormat: (name) => `fa fa-Ethereum`,
  style: { background: "#000" },
  activeStyle: { background: "#293e69" },
};
const MyEthereumLoginButton = createButton(config);

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const user = useContext(UserContext);
  const order = useContext(OrderContext);
  const { showFAQmodalLeaderboard, setShowFAQmodalLeaderboard } =
    useContext(UserContext);

  const { setWinnerLeaderboard, setLoserLeaderboard } =
    useContext(LeaderboardContext);

  const [showFAQmodal, setShowFAQmodal] = useState(false);

  const { setSignUpModalShow, signUpModalShow } = useContext(UserContext);
  const [logInModalShow, setLogInModalShow] = useState(false);

  const tl = gsap.timeline();

  useEffect(() => {
    if (showFAQmodalLeaderboard) {
      modalClickHandler();
    }
  }, [showFAQmodalLeaderboard]);

  // Closing the modal with the escape key
  const handleEscape = (event) => {
    if (event.code === "Escape" && showFAQmodal) {
      modalCloseHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });

  const modalClickHandler = () => {
    setShowFAQmodal(true);

    setTimeout(() => {
      tl.to(".faqmodal", { opacity: 1 })
        .to(
          ".faqmodal",
          {
            width: "35rem",
            ease: "power4.out",
          },
          "-=0.3"
        )
        .to(".faqmodal-header", { opacity: 1 }, "-=0.3")
        .from(".question", { y: 20, opacity: 0, stagger: 0.1 }, "-=0.3");
    }, 1);
  };

  const modalCloseHandler = () => {
    tl.to(".faqmodal", { duration: 0.3, opacity: 0 })
      .to(".faqmodal-back", { duration: 0.3, opacity: 0 })
      .then(() => {
        setShowFAQmodal(false);
        setShowFAQmodalLeaderboard(false);
      });
  };

  const isLeaderboard = location.pathname === "/leaderboard";
  return (
    <>
      {/* Sign in modal */}
      <Modal
        visible={signUpModalShow}
        close={() => setSignUpModalShow(false)}
        title="SIGN UP"
      >
        <div className="signup-walletadress">
          <div className="warning-text">
            *In order to participate verify your wallet address and social
            handle.
          </div>
          <div className="modal-wallet-wrapper">
            <div className="wallet-text">Wallet:</div>
            <div className="wallet-adress">
              {user.userInfo.shortWalletAdress}
            </div>
          </div>
        </div>

        <div className="signup-buttons-wrapper">
          <div className="login-discord" onClick={() => user.authWithDiscord()}>
            <span>Connect with Discord</span>
          </div>

          <div className="login-twitter" onClick={() => user.authWithTwitter()}>
            <span>Connect with TWITTER</span>
          </div>
        </div>
      </Modal>

      <Modal
        visible={logInModalShow}
        close={() => setLogInModalShow(false)}
        title="Log In"
      >
        <div className="flex-between">
          <MyEthereumLoginButton
            onClick={async () => {
              localStorage.removeItem("id");
              localStorage.removeItem("jwt_token");
              setLogInModalShow(false);
              let wallet = await user.connectWallet();
              if (wallet) {
                setSignUpModalShow(true);
              }
            }}
          >
            <span>Login with Wallet</span>
          </MyEthereumLoginButton>
        </div>
      </Modal>
      {/* Sign in modal */}

      {showFAQmodal && (
        <>
          <div
            onClick={(e) => {
              modalCloseHandler();
              handleEscape(e);
            }}
            className="faqmodal-back"
          />
          <div className="faqmodal">
            <div className="faqmodal-header">
              <div className="faqmodal-header-title">
                Trading Rules and FAQs
              </div>
              <div
                onClick={(e) => {
                  modalCloseHandler();
                  handleEscape(e);
                }}
                className="faqmodal-header-close"
              >
                <CloseIcon />
              </div>
            </div>
            <div className="faqmodal-content">
              <div className="question">
                <div className="faqmodal-content-title">
                  1. How do I play? What is the objective?
                </div>
                <p>
                  The objective of the game is to lose money. In order to play
                  the game, users need to connect their wallet along with their
                  social handle (Twitter or Discord). Once the account has been
                  created, users would be able to place market/limit orders on
                  the live ETH chart using the dummy tokens given to each user.
                </p>
              </div>
              <div className="question">
                <div className="faqmodal-content-title">
                  2. Do we need ETH/USD to play the game?
                </div>
                <p>
                  The simple answer is no! All the users would be deployed
                  $1,000,000 worth of dummy tokens to play the ETH simulator
                  game.
                </p>
              </div>
              <div className="question">
                <div className="faqmodal-content-title">
                  3. How do I win the game and get on the allowlist?
                </div>
                <p>
                  Each week the top 100 worst traders would be allocated an
                  allowlist spot. Remember, the objective of the game is to lose
                  the most amount of money in a week.
                </p>
              </div>
              <div className="question">
                <div className="faqmodal-content-title">
                  4. What happens when I reset my balance?
                </div>
                <p>
                  Resetting the balance refreshes all the trades and resets the
                  wallet balance to the base $1,000,000. This action would also
                  reset the profit/loss to 0 as if you are a new player. Note,
                  this action cannot be undone.
                </p>
              </div>
              <div className="question">
                <div className="faqmodal-content-title">
                  5. Why am I unable to play the game?
                </div>
                <p>
                  A user can win only one allowlist spot. If you’ve won in any
                  of the weeks, you would not be able to play the game further
                  and would be granted 1x allowlist spot.
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="header">
        <div onClick={modalClickHandler} className="header-left-side">
          <InfoIcon />
          <div className="trading-rules">
            {!isSmallDevice && "Trading Rules and FAQ"}
          </div>
        </div>

        <div
          className={
            user.userInfo.islogin ? "header-middle logged" : "header-middle"
          }
        >
          <NavLink to="/">SIMULATOR</NavLink>
          <div
            className={
              isLeaderboard ? "leaderboard-button active" : "leaderboard-button"
            }
          >
            <span>LEADERBOARD</span>

            <div className="leaderboard-pop-wrapper">
              <button
                onClick={() => {
                  setWinnerLeaderboard(true);
                  setLoserLeaderboard(false);

                  setTimeout(() => {
                    navigate("/leaderboard");
                  }, 10);
                }}
                className="leaderboard-pop-btn"
              >
                WINNER
              </button>
              <button
                onClick={() => {
                  setWinnerLeaderboard(false);
                  setLoserLeaderboard(true);

                  setTimeout(() => {
                    navigate("/leaderboard");
                  }, 10);
                }}
                className="leaderboard-pop-btn"
              >
                LOSER
              </button>
            </div>
          </div>
          {user.userInfo.islogin && <NavLink to="/profile">PROFILE</NavLink>}
        </div>

        <div className="header-right-side">
          {user.userInfo.islogin && (
            <div className="info-box-balance-wrapper">
              <div className="info-box-balance-number">
                <span style={{ color: "#dedede75" }}>BALANCE:</span>{" "}
                {Math.floor(
                  user.userInfo.usd +
                    user.userInfo.usd_holding +
                    user.userInfo.eth * order.ethPrice +
                    user.userInfo.eth_holding * order.ethPrice
                )}
                {isSmallDevice && "$"}
              </div>
              {user.userInfo.islogin && <img src={USDPixelized} alt="" />}
            </div>
          )}
          <LoginButton />
        </div>
      </div>
    </>
  );
};

export default Header;
